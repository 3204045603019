import gsap from 'gsap';

export function initializeSlider() {
    const slides = document.querySelectorAll(".slide");

    const slideWidths = {
        576: {
            slide: "20%",
            slideOpen: "80%"
        },
        768: {
            slide: "20%",
            slideOpen: "80%"
        },
        1200: {
            slide: "15%",
            slideOpen: "85%"
        },
        // Ajoutez d'autres tailles d'écran et leurs valeurs ici
    };

    function adjustSlideWidths() {
        slides.forEach(slide => {
            if (slide.classList.contains("open")) {
                gsap.to(slide, { width: slideWidths[getScreenSize()].slideOpen, duration: 0 });
            } else {
                gsap.to(slide, { width: slideWidths[getScreenSize()].slide, duration: 0 });
            }
        });
    }

    // Ajustez la taille des slides lors du chargement de la page
    adjustSlideWidths();

    slides.forEach(slide => {
        slide.addEventListener("click", function() {
            
            if (slide.classList.contains("open")) {
                return;
            } else {
                slides.forEach(s => {
                    if (s.classList.contains("open")) {
                        gsap.to(s, { width: slideWidths[getScreenSize()].slide, duration: 0.4, ease: "power3.inOut", onStart: () => s.classList.remove("open") });
                    }
                });
                gsap.to(slide, { width: slideWidths[getScreenSize()].slideOpen, duration: 0.4, ease: "power3.inOut", onStart: () => slide.classList.add("open") });
            }
        });
    });

    function getScreenSize() {
        // Déterminez la taille de l'écran en fonction de la largeur de la fenêtre
        const windowWidth = window.innerWidth;

        for (const size in slideWidths) {
            if (windowWidth <= size) {
                return size;
            }
        }

        // Utilisez la dernière valeur par défaut si la taille de l'écran est plus grande que toutes les valeurs définies
        return Object.keys(slideWidths)[Object.keys(slideWidths).length - 1];
    }

    // Mettez en place un gestionnaire d'événement pour ajuster la taille des slides lors du redimensionnement de la fenêtre
    window.addEventListener('resize', adjustSlideWidths);
}
