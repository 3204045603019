import gsap from 'gsap';

export class Menu {
  constructor() {

    this.btnMenuMobile = document.querySelector('.header__burger');
    this.header = document.querySelector('.header');
    this.menu = document.querySelector('.header__menu');
    this.menuItems = document.querySelectorAll('.header__menu a');
    this.menuItemsHome = document.querySelector('a[href="/"]');
    this.windowWidth = window.innerWidth;
    this.isMenuMobile = this.windowWidth <= 720 ? true : false;
    this.isMenuOpen = false;
    this.mainTimeline = gsap.timeline({ paused: true });
    this.delayResize = 250;

    this.mainTimeline.to(this.menu, {
      bottom: 0,
      duration: 0.5,
      ease: 'power2.inOut'
    });

    this.mainTimeline.to(this.menuItems, {
      opacity: 1,
      y: 0,
      duration: 0.5,
      ease: 'power2.inOut',
      stagger: 0.1,
    }, '-=0.2');

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.resizeScreen();
      }, this.delayResize);
    });

    this.btnMenuMobile.addEventListener('click', () => {
      this.toggleMenu()
    })

  }

  resizeScreen(){
    this.windowWidth = window.innerWidth;

    if(this.windowWidth > 720 && this.isMenuOpen){
      this.mainTimeline.set(this.menu, { clearProps: 'all' }, '+=0.5');
      this.mainTimeline.set(this.menuItems, { clearProps: 'all' }, '+=0.5');
    }
  }

  toggleMenu(){
    if(this.isMenuOpen){
      this.closeMenu();
    }else{
      this.openMenu();
    }
  }

  openMenu(){
    this.header.classList.add('menu-open');
    this.mainTimeline.play();
    this.isMenuOpen = true;
  }

  closeMenu(){
    if(this.isMenuOpen){
      this.header.classList.remove('menu-open');
      this.isMenuOpen = false;
      this.mainTimeline.reverse();
    }
  }

  activeClassLink(currentPageUrl) {
    this.menuItems.forEach((item) => {

      let itemAttribute = '/' + item.getAttribute('href').replace(".html", "");
      if ((item.getAttribute('href') === currentPageUrl) || (itemAttribute === currentPageUrl)) {
        item.classList.add('active');
      }else{
        item.classList.remove('active');
      }
    });
  }
}
