import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export function fadeInAnimation(element, duration=0.5) {
  element.style.opacity = 0;
  return gsap.to(element, {
    opacity: 1,
    duration: duration,
    ease: 'power2.out',
    scrollTrigger: {
        trigger: element,       // L'élément qui sert de déclencheur pour l'animation.
        start: 'top center',        // L'animation commence lorsque le centre de l'élément atteint le centre de la fenêtre.
        toggleActions: 'play none none none', // Spécifie le comportement de l'animation lors du défilement (jouer une fois et ne pas inverser).
        scrub: false            // L'option "scrub" est désactivée, ce qui signifie que l'animation ne se synchronise pas avec le défilement.
    },
  });
}