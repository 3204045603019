import '../styles/index.scss';
import { elementsToAnimate } from './elements-animate/elements-animate';
import { contactForm } from './contact';
import { Menu } from './menu';
import { initializeSlider } from './slider-home';
import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
const swup = new Swup({
    containers: ["#swup"],
    plugins: [new SwupPreloadPlugin()]
});

const menu = new Menu();

function init() {
    const currentPath = swup.currentPageUrl;
    const pathWithoutHtml = currentPath.replace(".html", ""); 
    const elements = document.querySelectorAll('.elementToAnimate');

    let logoType = 'default';
    if (pathWithoutHtml === '/atelier') {
      logoType = 'atelier';
    } else if (pathWithoutHtml === '/consulting') {
      logoType = 'consulting';
    }
  
    document.querySelector('.header__logo img').setAttribute('src', `../images/logo-${logoType}.svg`);

    if (elements.length > 0) {
        elementsToAnimate(elements);
    }

    if (pathWithoutHtml === '/contact') {
        contactForm();
    }

    if (pathWithoutHtml === '/') {
        initializeSlider();
    }

    menu.activeClassLink(pathWithoutHtml);
}

swup.hooks.on('page:view', () => {
    init();
});

swup.hooks.on('link:click', () => {
    menu.closeMenu();
});

init();
