import { fadeInAnimation } from './animations/fade-in.js';
import { overlayAnimation } from './animations/overlay.js';

export function elementsToAnimate(elements){

    // Parcourez les éléments et détectez quand ils deviennent visibles
    elements.forEach((element) => {
    const animationClass = element.dataset.animation; // Obtenez la classe d'animation depuis l'attribut "data-animation"
    if (animationClass) {
        // Si une classe d'animation est définie
        // Utilisez la classe d'animation pour déterminer quelle animation à déclencher
        switch (animationClass) {
        case 'fadeIn':
            fadeInAnimation(element, 0.5);
            break;
        case 'overlay':
            overlayAnimation(element, 0.5);
            break;
        }
    }
    });
}
