import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export function overlayAnimation(overlay, duration = 1) {
  return gsap.to(overlay, {
    width: '100%', // La largeur atteint 100% pour couvrir l'image
    duration: duration,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: overlay,       // L'élément qui sert de déclencheur pour l'animation.
      start: 'top center',        // L'animation commence lorsque le centre de l'élément atteint le centre de la fenêtre.
      end: 'center center',      // L'animation se termine lorsque le haut de l'élément atteint le centre de la fenêtre.
      toggleActions: 'play none none none', // Spécifie le comportement de l'animation lors du défilement (jouer une fois et ne pas inverser).
      scrub: false            // L'option "scrub" est désactivée, ce qui signifie que l'animation ne se synchronise pas avec le défilement.
  },
    onComplete: () => {
      gsap.to(overlay, {
        width: 0, // La largeur revient à 0
        duration: duration,
        ease: 'power2.in',
      });
    },
  });
}
